import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`The General Data Protection Regulation (GDPR)`}</h2>
    <p>{`Dashbot takes the privacy and security of our customer data very seriously. We are committed to working with our customers to provide actionable insights while maintaining the privacy and security of their critical data. With the General Data Protection Regulation (GDPR) due to become effective on May 25th, 2018, we have put together this documentation to explain in detail how to comply with the regulations while using Dashbot.`}</p>
    <h2>{`What is the GDPR?`}</h2>
    <p>{`According to `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/General_Data_Protection_Regulation"
      }}>{`Wikipedia`}</a>{`:`}</p>
    <p>{`“The General Data Protection Regulation (GDPR) (EU) 2016/679 is a regulation in EU law on data protection and privacy for all individuals within the European Union. It addresses the export of personal data outside the EU. The GDPR aims primarily to give control back to citizens and residents over their personal data and to simplify the regulatory environment for international business by unifying the regulation within the EU.”`}</p>
    <p>{`The GDPR goes into effect on May 25, 2018.`}</p>
    <p>{`Here are some of our favorite links to understand GDPR:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gdpr-info.eu/"
        }}>{`Searchable Text of the GDPR`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/gdpr#"
        }}>{`Facebook’s GDPR Site`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/m/messenger-and-the-gdpr"
        }}>{`“The Messenger Platform and the GDPR”, from Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://opengdpr.org/"
        }}>{`OpenGDPR`}</a></li>
    </ul>
    <p>{`The General Data Protection Regulation (“GDPR”) is anew comprehensive data protection lawin the European Union (“EU”) that updates existing laws to strengthen the protection ofpersonal data in light of rapid technological developments, increased globalization, and morecomplex international flows of personal data. It replaces the patchwork of national dataprotection laws currently in place with a single set of rules, directly enforceable in each EUmember state. The GDPR goes into effect on May 25, 2018.`}</p>
    <p>{`The General Data Protection Regulation (“GDPR”) is a`}</p>
    <p>{`new comprehensive data protection lawin the European Union (“EU”) that updates existing laws to strengthen the protection ofpersonal data in light of rapid technological developments, increased globalization, and morecomplex international flows of personal data. It replaces the patchwork of national dataprotection laws currently in place with a single set of rules, directly enforceable in each EUmember state. The GDPR goes into effect on May 25, 2018.`}</p>
    <h2>{`How does GDPR impact Dashbot and its customers?`}</h2>
    <p>{`The GDPR regulates the “processing” of personal data of any EU resident, known as the “data subject”.`}</p>
    <p>{`In the terms of the GDPR, Dashbot is the “data processor” and the customer is the “data controller.” The data controller collects data from “data subjects” (ie. the customer’s end users) and the data processor receives this data from the data controller.`}</p>
    <h2>{`Will Dashbot be compliant with the GDPR?`}</h2>
    <p>{`Yes. Dashbot is committed to complying with the GDPR and working with our customers to enable them to comply with the GDPR as well.`}</p>
    <h2>{`Who are Dashbot’s sub-processors?`}</h2>
    <p>{`As a “data processor,” Dashbot’s sub-processors are:`}</p>
    <ul>
      <li parentName="ul">{`Amazon Web Services, Inc. — Seattle, WA, USA`}</li>
      <li parentName="ul">{`Snowflake Computing Inc. — San Mateo, CA, USA`}</li>
    </ul>
    <h2>{`Does Dashbot have a Data Processing Agreement (DPA) in place?`}</h2>
    <p>{`Yes. Dashbot has updated our Terms and Conditions with a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/legal/dpa"
      }}><strong parentName="a">{`Data Processing Agreement`}</strong></a>{` (DPA) that outlines the specific requirements and obligations as a data processor. Dashbot also has DPAs in place with all of our sub-processors to ensure downstream compliance.`}</p>
    <h2>{`What should I do if I receive a data subject information or deletion request?`}</h2>
    <p>{`In the event that you receive an information or deletion request from a data subject, email `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:gdpr@dashbot.io"
        }}>{`gdpr@dashbot.io`}</a></strong>{` with the subject “GDPR Request” from the email address associated with the bot account, with the following information:`}</p>
    <ul>
      <li parentName="ul">{`Type of request: information or deletion`}</li>
      <li parentName="ul">{`Bot Settings URL: `}<em parentName="li">{`eg. `}<a parentName="em" {...{
            "href": "https://reports.dashbot.io/bots/123"
          }}>{`https://reports.dashbot.io/bots/123`}</a></em></li>
      <li parentName="ul">{`API Key, as found on the Bot Settings URL`}</li>
      <li parentName="ul">{`User ID`}</li>
    </ul>
    <p>{`The user ID is the user ID that is passed to Dashbot as found in the integration API — for Facebook bots, this is typically the “Page Scoped User ID” or (PSID).`}</p>
    <p>{`Information and deletion requests will be processed in a timely manner, and is irreversible.`}</p>
    <h2>{`What happens to my analytics after a data subject deletion request?`}</h2>
    <p>{`Personal data associated with this user ID will be deleted — any data that is rolled up in anonymous aggregates will not be recalculated.`}</p>
    <p>{`For this example, a user with the user ID “123” chats with your bot on Facebook. From Facebook, we have: first name, last name, timezone, profile photo URL, gender, locale and timezone offset.`}</p>
    <p>{`After a deletion request is received, we will remove the record that stores the association between the user ID “123” and the associated user data.`}</p>
    <p>{`If the user is included in the “Top Users” report, the user with the user ID “123” will be removed from that report. If you were to access the transcripts for user ID “123”, you will not be able to see this user’s messages.`}</p>
    <p>{`Anonymous aggregate reports will not be recalculated.`}</p>
    <p>{`If you send Dashbot new data with this user ID after the deletion request, data processing will occur once more for this user ID, so it is possible that their personal data may re-appear.`}</p>
    <h2>{`What about the website you operate at `}<a parentName="h2" {...{
        "href": "http://www.dashbot.io"
      }}>{`www.dashbot.io`}</a>{`?`}</h2>
    <p>{`With respect to the websites we operate at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}>{`www.dashbot.io`}</a>{`, reports.dashbot.io and other dashbot.io domains, Dashbot is the “data controller” for the users of our website, who would be the “data subjects.” We use cookies to keep track of usage and analytics for the purposes of improving our website product. And, we store user login information in order to provide the Dashbot services.`}</p>
    <p>{`We use the following “data processors” to improve and analyze our website product, and have DPAs in place with all of them to ensure GDPR compliance for our own data subjects:`}</p>
    <ul>
      <li parentName="ul">{`Customer.io`}</li>
      <li parentName="ul">{`Google`}</li>
      <li parentName="ul">{`Heap Analytics`}</li>
      <li parentName="ul">{`Mailchimp`}</li>
      <li parentName="ul">{`Mixpanel`}</li>
      <li parentName="ul">{`Salesforce`}</li>
      <li parentName="ul">{`Segment`}</li>
      <li parentName="ul">{`SendGrid`}</li>
      <li parentName="ul">{`Stripe`}</li>
    </ul>
    <h2>{`And finally, a legal disclaimer…`}</h2>
    <p>{`Nothing stated here is legal, compliance or other advice. It is provided only for your informational and convenience purposes, and is our commentary on the GDPR, as Dashbot interprets it, as of the date of publication. You should work closely with legal and other professional advisors to determine exactly how the GDPR may or may not apply to you. We have spent a lot of time understanding what the GDPR means for us and our customers, and we hope you do the same.`}</p>
    <p>{`As stated, Dashbot is a processor of data which you, as the controller of your users’ data, make available to us. Dashbot can never engage directly with your users nor address their requests. You remain in charge of meeting your data subject users’ requests and we can help you by providing tools to streamline this process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      